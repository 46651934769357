import React, { useEffect } from 'react';
import { Modal, Box, useMediaQuery } from '@mui/material';
import { useModalContext } from '../hooks/ModalContext';
import theme from '../theme';
import { useSwipeable } from 'react-swipeable';
import useImage from '../hooks/useImage';
import { ImageIdAndScore } from '../types/ImageMetadata';

interface LargeImageProps {
  imageIds: ImageIdAndScore[];
}

const LargeImage = ({ imageIds }: LargeImageProps) => {
  const { open, setOpen, selectedIndex, setSelectedIndex } = useModalContext();
  const id = imageIds[selectedIndex].id;
  const { image, upvote, downvote } = useImage(id);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => downvote(),
    onSwipedRight: () => upvote(),
    onSwipedUp: () => setSelectedIndex((selectedIndex + 1) % imageIds.length),
    onSwipedDown: () =>
      setSelectedIndex((selectedIndex - 1 + imageIds.length) % imageIds.length),
    preventScrollOnSwipe: true,
  });

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (imageIds)
        if (event.key === 'ArrowRight') {
          setSelectedIndex((selectedIndex + 1) % imageIds.length);
        } else if (event.key === 'ArrowLeft') {
          setSelectedIndex(
            (selectedIndex - 1 + imageIds.length) % imageIds.length,
          );
        }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, selectedIndex, imageIds, setSelectedIndex]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
      {...handlers}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          pointerEvents: 'none',
        }}
      >
        <img
          src={`api/uploads/${image?.filename}`}
          alt="Selected"
          style={{ maxWidth: '90%', maxHeight: '75%' }}
        />
      </Box>
    </Modal>
  );
};

export default LargeImage;
