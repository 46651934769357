import React, { useCallback } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  Grid,
  Link,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Casino, HeightTwoTone, Twitter } from '@mui/icons-material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchStats } from '../services/api';
import store from '../store/store';
import { useSnapshot } from 'valtio';

const Header: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const query = useQuery({ queryKey: ['stats'], queryFn: fetchStats });
  const client = useQueryClient();
  const { sorting } = useSnapshot(store);

  const handleSortByScore = useCallback(() => {
    client.invalidateQueries({ queryKey: ['images'] });
    store.sorting = 'upvotes';
  }, [client]);

  const handleSortRandom = useCallback(() => (store.sorting = 'random'), []);

  return (
    <Grid container direction={'column'} alignItems="center" flex={0}>
      <Box paddingTop={isMobile ? 1.5 : 4} paddingBottom={isMobile ? 1 : 2}>
        <Typography
          color={'secondary'}
          variant={isMobile ? 'h6' : 'h3'}
          component="div"
          sx={{
            flexGrow: 1,
            textAlign: 'center',
            marginBottom: isMobile ? 0 : 2,
          }}
        >
          pepe.page
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography
            color={'secondary'}
            variant={'body1'}
            component="div"
            sx={{
              textAlign: 'center',
            }}
          >
            {query.data && `Sort ${query.data.total_images} pepes by:`}
          </Typography>
          <IconButton
            size="small"
            color="secondary"
            disabled={sorting === 'upvotes'}
            title="Score"
            onClick={handleSortByScore}
          >
            <HeightTwoTone />
          </IconButton>
          <IconButton
            size="small"
            color="secondary"
            disabled={sorting === 'random'}
            title="Random"
            onClick={handleSortRandom}
          >
            <Casino />
          </IconButton>
        </Box>
      </Box>
      <Link
        target="blank"
        color="secondary"
        href="https://twitter.com/pepedotpage"
        title="Pepe.page's twitter profile."
        sx={{
          position: 'absolute',
          right: isMobile ? 10 : 20,
          top: isMobile ? 10 : 20,
        }}
      >
        <Twitter
          sx={{ width: isMobile ? 30 : 40, height: isMobile ? 30 : 40 }}
        />
      </Link>
    </Grid>
  );
};

export default Header;
