import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

interface GallerySize {
  width: number;
  height: number;
}

function useGallerySize(): GallerySize {
  const { width, height } = useWindowSize();
  const [gallerySize, setGallerySize] = useState<GallerySize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const w = width;
    const h = height;
    setGallerySize({ width: w, height: h });
  }, [height, width]);

  return gallerySize;
}

export default useGallerySize;
