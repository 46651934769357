// src/Login.tsx
import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import store from '../store/store';
import { useMutation } from '@tanstack/react-query';
import { login } from '../services/api';
import { User } from '../types/User';

interface LoginProps {
  onLoggedIn: () => void;
}
const Login = ({ onLoggedIn }: LoginProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const mutation = useMutation({
    mutationFn: login,
    onSuccess: (data: User | undefined) => {
      store.user = data || null;
      onLoggedIn();
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await mutation.mutateAsync({ username, password });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <TextField
        InputLabelProps={{
          style: {
            backgroundColor: 'white',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
          },
        }}
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        margin="normal"
        fullWidth
        style={{
          padding: 2,
          backgroundColor: 'white',
          borderRadius: 5,
        }}
      />
      <TextField
        InputLabelProps={{
          style: {
            backgroundColor: 'white',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
          },
        }}
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        fullWidth
        style={{
          padding: 2,
          backgroundColor: 'white',
          borderRadius: 5,
        }}
      />
      <Button
        color={'secondary'}
        disabled={!username || !password}
        type="submit"
        variant="contained"
        style={{
          marginTop: 16,
        }}
      >
        Login
      </Button>
    </Box>
  );
};

export default Login;
