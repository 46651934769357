import React, { useCallback, useMemo } from 'react';
import { Card, CardActions, Box, IconButton } from '@mui/material';
import { ArrowDownward, ArrowUpward, Delete } from '@mui/icons-material';
import store from '../store/store';
import { useSnapshot } from 'valtio';
import { useModalContext } from '../hooks/ModalContext';
import useImage from '../hooks/useImage';

interface ImageCardProps {
  index: number;
  id: number;
  onDelete: (id: number) => void;
}

const ImageCard = ({ index, id, onDelete }: ImageCardProps) => {
  const { user } = useSnapshot(store);
  const { setOpen, setSelectedIndex } = useModalContext();
  const { image, upvote, downvote } = useImage(id);

  const canDelete = useMemo(() => {
    return user && user.id === image?.user_id;
  }, [image?.user_id, user]);

  const handleClick = useCallback(() => {
    setSelectedIndex(index);
    setOpen(true);
  }, [index, setOpen, setSelectedIndex]);

  const handleUpvote = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      upvote();
    },
    [upvote],
  );

  const handleDownvote = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      downvote();
    },
    [downvote],
  );

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the onClick handler from being called
      onDelete && image && onDelete(image.id);
    },
    [onDelete, image],
  );

  return (
    <Card
      onClick={handleClick}
      sx={[
        { cursor: 'pointer', backgroundColor: '#00000010' },
        { height: '100%' },
      ]}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40,
            maxWidth: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }}
        >
          <IconButton
            color={image?.user_vote === 'up' ? 'success' : 'secondary'}
            size="small"
            style={{ marginRight: 10 }}
            onClick={handleUpvote}
          >
            <ArrowUpward />
          </IconButton>
          <div
            style={{
              color: 'white',
              fontSize: 16,
              textAlign: 'center',
            }}
          >
            {image?.score && (
              <>
                {image.score > 0 ? '+' : ''}
                {image.score}
              </>
            )}
          </div>
          <IconButton
            color={image?.user_vote === 'down' ? 'success' : 'secondary'}
            size="small"
            style={{ marginLeft: 10 }}
            onClick={handleDownvote}
          >
            <ArrowDownward />
          </IconButton>
          {canDelete && (
            <CardActions>
              <IconButton
                size="small"
                color="secondary"
                style={{ marginTop: 10 }}
                onClick={handleDelete}
              >
                <Delete />
              </IconButton>
            </CardActions>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {image && (
            <img
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                margin: 'auto',
              }}
              src={`api/uploads/${image.filename}`}
              alt={image.filename}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default ImageCard;
