import React, { PropsWithChildren, useContext, useState } from 'react';

interface ModalContext {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
}

const Context = React.createContext<ModalContext | undefined>(undefined);

export const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Context.Provider
      value={{ open, setOpen, selectedIndex, setSelectedIndex }}
    >
      {children}
    </Context.Provider>
  );
};

export function useModalContext(): ModalContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useAuthentication must be used within a AuthenticationProvider',
    );
  }
  return context;
}
