// store.js
import { proxy } from 'valtio';
import { User } from '../types/User';
import { Sorting } from '../types/Sorting';

export interface State {
    user: User | null
    tagQuery: string[]
    userQuery: string | undefined
    sorting: Sorting
}

const store = proxy<State>({
    user: null,
    tagQuery: [],
    userQuery: undefined,
    sorting: 'random'
});

export default store;
