import React, { PropsWithChildren, useState } from 'react';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarCloseReason } from '@mui/material/Snackbar/Snackbar';

interface ClipboardLinkProps {
  textToCopy: string;
}

const ClipboardLink = ({
  textToCopy,
  children,
}: PropsWithChildren<ClipboardLinkProps>) => {
  const [open, setOpen] = useState(false);

  const handleClick = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    try {
      await navigator.clipboard.writeText(textToCopy);
      setOpen(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Link
        href='#'
        onClick={handleClick}
        color={'secondary'}
        title={'Copy wallet address to clipboard.'}
      >
        {children}
      </Link>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message='Address copied to clipboard!'
      />
    </>
  );
};

export default ClipboardLink;
