import React, { forwardRef, useCallback, useMemo } from 'react';
import { useMediaQuery } from '@mui/material';
import ImageCard from './ImageCard';
import { useSnapshot } from 'valtio';
import store from '../store/store';
import { FixedSizeGrid as Grid } from 'react-window';
import useGallerySize from '../hooks/useGallerySize';
import theme from '../theme';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteImage, fetchImageIds, fetchStats } from '../services/api';
import LargeImage from './LargeImage';
import { ModalContextProvider } from '../hooks/ModalContext';

const ImageGallery = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const columnCount = useMemo(
    () => (isSmallScreen ? 1 : isMediumScreen ? 2 : isLargeScreen ? 3 : 4),
    [isSmallScreen, isLargeScreen, isMediumScreen],
  );

  const { tagQuery, userQuery, sorting } = useSnapshot(store);

  const queryClient = useQueryClient();

  const imagesQuery = useQuery({
    queryKey: ['images', tagQuery, userQuery],
    queryFn: fetchImageIds,
  });

  const imageDatas = useMemo(() => {
    if (imagesQuery.data) {
      let fetchedImages = imagesQuery.data;

      if (sorting === 'random') {
        for (let i = fetchedImages.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [fetchedImages[i], fetchedImages[j]] = [
            fetchedImages[j],
            fetchedImages[i],
          ];
        }
      } else fetchedImages = fetchedImages.sort((a, b) => b.score - a.score);

      return fetchedImages;
    }
  }, [imagesQuery.data, sorting]);

  const statsQuery = useQuery({ queryKey: ['stats'], queryFn: fetchStats });

  const stats = useMemo(
    () => statsQuery.data ?? { total_images: 0, total_size: '0 B' },
    [statsQuery.data],
  );

  const mutation = useMutation({
    mutationFn: deleteImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['images'] });
    },
  });

  const handleDelete = useCallback(
    async (id: number) => await mutation.mutateAsync(id),
    [mutation],
  );

  const renderCell = useCallback(
    ({ columnIndex, rowIndex, style }: any) => {
      const index = rowIndex * columnCount + columnIndex;
      const imageId = imageDatas ? imageDatas[index]?.id : undefined;

      if (!imageId) {
        return null;
      }

      return (
        <div
          style={{
            ...style,
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 20,
          }}
        >
          <ImageCard index={index} id={imageId} onDelete={handleDelete} />
        </div>
      );
    },
    [columnCount, handleDelete, imageDatas],
  );

  const { width, height } = useGallerySize();

  const OuterElementType = forwardRef<HTMLDivElement, {}>(
    (props: React.HTMLAttributes<HTMLDivElement>, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
          ...props.style,
          width: '100vw',
          position: 'relative',
          left: '50%',
          right: '50%',
          marginLeft: '-50vw',
          marginRight: '-50vw',
          flex: 1,
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      />
    ),
  );

  const InnerElementType = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
  >(({ style, ...rest }, ref) => (
    <div style={{ width: '100%' }}>
      <div
        ref={ref}
        {...rest}
        style={{
          ...style,
          width: width * 0.75,
          position: 'relative',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </div>
  ));

  const finalWidth = useMemo(() => width * 0.75, [width]);
  const finalHeight = useMemo(() => height * 0.75, [height]);

  return (
    <ModalContextProvider>
      <Grid
        key={sorting}
        outerElementType={OuterElementType}
        innerElementType={InnerElementType}
        columnCount={columnCount}
        columnWidth={finalWidth / columnCount}
        height={finalHeight}
        rowCount={Math.ceil(stats.total_images / columnCount)}
        rowHeight={finalWidth / columnCount}
        width={finalWidth}
      >
        {renderCell}
      </Grid>
      {imageDatas && <LargeImage imageIds={imageDatas} />}
    </ModalContextProvider>
  );
};

export default ImageGallery;
