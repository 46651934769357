import React, { ChangeEvent, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadImage } from '../services/api';

const ImageUpload: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: uploadImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['images'] });
    },
  });

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLoading(true);
      try {
        const files = Array.from(event.target.files);
        const batchSize = 10;
        for (let i = 0; i < files.length; i += batchSize) {
          const batch = files.slice(i, i + batchSize);
          const formData = new FormData();
          batch.forEach((file) => {
            formData.append('images', file);
          });
          await mutation.mutateAsync(formData);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        if (event.target) {
          event.target.value = '';
        }
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Button color={'secondary'} component="label" variant="contained">
        Upload Images
        <input
          type="file"
          accept="image/*"
          hidden
          multiple
          onChange={handleUpload}
        />
      </Button>
      {loading && (
        <CircularProgress color={'secondary'} sx={{ marginLeft: 3 }} />
      )}
    </>
  );
};

export default ImageUpload;
