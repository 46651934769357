import React, { useCallback, useState } from 'react';
import {
  Modal,
  Button,
  Box,
  useMediaQuery,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import ImageUpload from './ImageUpload';
import Login from './Login';
import { useSnapshot } from 'valtio';
import store from '../store/store';
import { useTheme } from '@mui/material/styles';
import ClipboardLink from './ClipboardLink';
import { useMutation } from '@tanstack/react-query';
import { logout } from '../services/api';

const Footer: React.FC = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSnapshot(store);
  const [open, setOpen] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  const mutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      store.user = null;
    },
  });

  const handleLogin = () => {
    setOpen(true);
  };

  const handleLogout = useCallback(() => mutation.mutate(), [mutation]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonEnter = useCallback(() => setButtonVisible(true), []);
  const handleButtonLeave = useCallback(() => setButtonVisible(false), []);
  return (
    <>
      {!isMobile && (
        <Grid
          container
          direction={'row'}
          flex={0}
          sx={{
            padding: 1,
            backgroundColor: theme.palette.background.default,
            width: '100vw',
            position: 'relative',
            left: '50%',
            right: '50%',
            marginLeft: '-50vw',
            marginRight: '-50vw',
          }}
        >
          <Grid
            item
            flex={1}
            display={isTablet ? 'none' : 'flex'}
            alignItems={'flex-end'}
            justifyContent={'flex-start'}
          >
            {user && <ImageUpload />}
          </Grid>
          <Grid
            item
            flex={1}
            display={'flex'}
            flexDirection="row"
            justifyContent="center"
            alignItems={'center'}
          >
            <Typography color="secondary">Site by</Typography>
            <Link
              target="blank"
              color="secondary"
              href="https://twitter.com/hofman_eth"
              title="Hofman_eth's twitter profile."
              sx={{ marginLeft: 1 }}
            >
              @hofman_eth
            </Link>
          </Grid>

          <Grid
            item
            flex={1}
            display={'flex'}
            flexDirection="row"
            justifyContent="center"
            alignItems={'center'}
          >
            <Typography color="secondary">Built by</Typography>
            <Link
              target="blank"
              color="secondary"
              href="https://twitter.com/twilightsaurus"
              title="Twilightsaurus' twitter profile."
              sx={{ marginLeft: 1 }}
            >
              @twilightsaurus
            </Link>
          </Grid>
          <Grid
            item
            flex={1}
            display={'flex'}
            flexDirection="row"
            justifyContent="center"
            alignItems={'center'}
          >
            <Typography color="secondary" sx={{ marginRight: 1 }}>
              Tips:
            </Typography>
            <ClipboardLink
              textToCopy={'0xBf40D9554734b841369Bc1d351D182466847F7B5'}
            >
              0xB...F7B5
            </ClipboardLink>
          </Grid>
          <Grid
            item
            display={isTablet ? 'none' : 'flex'}
            flex={1}
            alignItems={'flex-end'}
            justifyContent={'flex-end'}
          >
            <Button
              onMouseEnter={handleButtonEnter}
              onMouseLeave={handleButtonLeave}
              component="label"
              variant="contained"
              color="secondary"
              onClick={user ? handleLogout : handleLogin}
              sx={{
                opacity: buttonVisible ? 1 : 0,
              }}
            >
              <Typography color="primary" fontFamily={'Comfortaa, sans-serif'}>
                {user ? 'Logout' : 'Login'}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <Login onLoggedIn={() => setOpen(false)} />
        </Box>
      </Modal>
    </>
  );
};

export default Footer;
