import React from 'react';
import { Container, Box, CssBaseline, ThemeProvider } from '@mui/material';
import ImageGallery from './components/ImageGallery';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Container maxWidth="lg" style={{ padding: 0 }}>
          <Box display={'flex'} flexDirection={'column'} maxHeight={'100vh'}>
            <Header />
            <ImageGallery />
            <Footer />
          </Box>
        </Container>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
