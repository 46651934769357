import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { fetchImageMetadata, upvoteImage, downvoteImage } from '../services/api';
import { ImageMetadata } from '../types/ImageMetadata';

interface ImageProps {
  image: ImageMetadata | undefined
  upvote: () => void
  downvote: () => void
}

function useImage(id: number): ImageProps {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [`metadata/${id}`],
    queryFn: fetchImageMetadata,
  });

  const upvoteMutation = useMutation({
    mutationFn: upvoteImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`metadata/${id}`] });
    },
  });

  const downvoteMutation = useMutation({
    mutationFn: downvoteImage,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`metadata/${id}`] });
    },
  });


  const upvote = useCallback(() => upvoteMutation.mutate(id), [id, upvoteMutation]);
  const downvote = useCallback(() => downvoteMutation.mutate(id), [downvoteMutation, id]);

  return { image: query.data, upvote, downvote }
}

export default useImage;
