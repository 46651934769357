// src/theme.ts
import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000',
        },
        secondary: {
            main: '#FFF',
        },
        background: {
            default: '#4C9540',
        },
    },
    typography: {
        fontFamily: [
            'Comfortaa',
            'sans-serif',
        ].join(','),
        h1: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        },
        h2: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        },
        h3: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        },
        h4: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        },
        h5: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        },
        h6: {
            fontFamily: [
                'Evermore',
                'sans-serif'
            ].join(',')
        }
    },
});

export default theme;
