import axios from 'axios';
import { QueryFunctionContext } from '@tanstack/react-query';
import { User } from '../types/User';
import { ImageIdAndScore, ImageMetadata } from '../types/ImageMetadata';

export const fetchImageIds = async ({ queryKey }: QueryFunctionContext<[string, readonly string[], string | undefined]>): Promise<ImageIdAndScore[] | undefined> => {
  try {
    let params = new URLSearchParams();
    const [, tags, user] = queryKey
    tags.forEach((t) => params.append('tags', t));
    if (user) params.append('user_id', user);

    const response = await axios.get(`/api/images`, { params: params });

    return response.data;
  } catch (error) {
    throw new Error(`Error fetching image ids: ${error}`);
  }
};

export async function fetchImageMetadata({ queryKey }: QueryFunctionContext): Promise<ImageMetadata> {
  try {
    const response = await axios.get(`/api/images/${queryKey}`)
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching image metadata: ${error}`);
  }
}

export const uploadImage = async (formData: FormData) => {
  try {
    await axios.post('/api/images', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

export const deleteImage = async (id: number) => {
  try {
    await axios.delete(`/api/images/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export const upvoteImage = async (imageId: number) => {
  try {
    const response = await axios.post(`/api/images/${imageId}/upvote`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to upvote image: ${error}`);
  }
}

export const downvoteImage = async (imageId: number) => {
  try {
    const response = await axios.post(`/api/images/${imageId}/downvote`);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to downupvote image: ${error}`);
  }
}

export interface Stats {
  total_images: number;
  total_size: string;
}

export const fetchStats = async (): Promise<Stats> => {
  try {
    const response = await axios.get('/api/images/stats');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching image stats: ${error}`);
  }
}

export interface Login {
  username: string;
  password: string;
}

export const login = async (login: Login): Promise<User | undefined> => {
  const { username, password } = login;
  try {
    const response = await axios.post('/api/login', { username, password });
    return response.data;
  } catch (error) {
    throw new Error(`Error logging in: ${error}`);
  }
};

export const logout = async () => {
  try {
    const response = await axios.post('/api/logout');
    console.log('Logged out:', response.data);
  } catch (error) {
    throw new Error(`Error logging out: ${error}`);
  }
};
